import React, { useState, useEffect, useContext, useRef } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import renderBlok from '@renderBlok'
import SbEditable from 'storyblok-react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import PageContext from '@context'
import useStickyFilter from '@hooks/use-sticky-filter'
import GalleryModule from '../integrations/gallery-module'
import classNames from 'classnames'
import get from 'lodash/get'
import { PageContainer, ContentContainer } from '@system'
import formatLocalizedLink from '@helpers/format-localized-link'

const useStyles = makeStyles((theme) => ({
  // Filter
  filterBarOuterContainer: {
    marginBottom: '40px',
    [theme.breakpoints.up('md')]: {
      position: 'sticky',
      zIndex: 1100,
      '&:before': {
        background: theme.palette.background.paper,
        boxShadow:
          '0px 2px 2px -1px rgba(0,0,0,0.2), 0px 4px 3px 0px rgba(0,0,0,0.14), 0px 1px 1px 0px rgba(0,0,0,0.12)',
        content: '""',
        height: '100%',
        left: '0',
        marginLeft: '50%',
        position: 'absolute',
        opacity: '0',
        top: '0',
        transform: 'translateX(-50%)',
        transition: '0.2s opacity, 0.2s visibility',
        visibility: 'hidden',
        width: 'var(--filter-bar-width)',
        zIndex: '-1',
      },
    },
  },
  filterBarOuterContainerSticky: {
    [theme.breakpoints.up('md')]: {
      '&:before': {
        opacity: '1',
        visibility: 'visible',
      },
    },
  },
}))

const IntegrationFilter = (props) => {
  const staticQueryData = useStaticQuery(graphql`
    query {
      allProducts {
        nodes {
          name
          product_website
          benefits
          categories
          id
          logo
          solution_description
          social_proofs {
            name
            quote
          }
          support_built
          support_email
          support_phone
          uid
          works_with
          company {
            contact_first_name
            contact_last_name
            contact_title
            description
            email
            id
            name
            uid
            address {
              address_1
              city
              country
              state
              zip
            }
          }
          articles {
            title
            url
          }
          product_description
          g2reviews {
            crowd_url
            id
            rating
            reviews {
              uid
              rating
              submitted_at
              title
              user {
                name
                title
                image
                company
              }
              answers {
                text
                value
                type
              }
            }
            write_review_url
          }
          built_with
          built_for
          screenshots {
            title
            url
            url_preview
          }
          meta_description
          video {
            title
            url_poster
            url_video
          }
          whitepaper {
            description
            image_url
            pdf_url
          }
        }
      }
    }
  `)

  const integrationsData = staticQueryData.allProducts.nodes
  const pageContext = useContext(PageContext)
  const classes = useStyles()
  const stickyContainerRef = useRef()
  const isSticky = useStickyFilter(stickyContainerRef.current)
  const [filteredData, setFilteredData] = useState(null)
  const [searchValue, setSearchValue] = useState(null)

  useEffect(() => {
    fetchFilteredData(integrationsData)
  }, [])

  const fetchFilteredData = (props) => {
    setFilteredData(
      props &&
        props.filter(
          (integratedData, index) =>
            index ===
            integrationsData.findIndex(
              (data) =>
                data.name.toLowerCase() === integratedData.name.toLowerCase()
            )
        )
    )
  }

  const convertToLowerCaseAndReplace = (stringToBeConverted) =>
    stringToBeConverted.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()

  const PLAN_GRID = 'Plan Grid'

  const extractFilterOptions = (filterName) => {
    let filterOptions = []
    !!integrationsData &&
      integrationsData.forEach((item) => {
        if (item[filterName]) {
          filterOptions = filterOptions.concat(
            typeof item[filterName] === 'string'
              ? [
                  item[filterName] === PLAN_GRID
                    ? item[filterName].replace(/\s/g, '')
                    : item[filterName],
                ].filter((option) => filterOptions.indexOf(option) === -1)
              : item[filterName].filter(
                  (option) => filterOptions.indexOf(option) === -1
                )
          )
        }
      })
    return filterOptions
  }

  let filterByParam = ''
  let filterSelectParam = ''

  if (typeof document !== 'undefined') {
    const params = new URL(document.location).search.split('?')
    if (params.length > 1) {
      filterByParam = params[1].split('=')[0]
      filterSelectParam = params[1].split('=')[1]
    }
    if (!!filterByParam && !!filterSelectParam) {
      filterSelectParam = extractFilterOptions(filterByParam).filter(
        (item) =>
          convertToLowerCaseAndReplace(item) ===
          convertToLowerCaseAndReplace(filterSelectParam)
      )
      filterSelectParam = get(filterSelectParam, '[0]')
    }
  }

  const [filters, setFilters] = useState({
    filterBy: filterByParam || '',
    filterSelect: filterSelectParam || '',
  })

  useEffect(() => {
    setFilters({
      filterBy: filterByParam,
      filterSelect: filterSelectParam,
    })
  }, [filterByParam, filterSelectParam])

  const onChangeFilters = (event, filterName) => {
    setSearchValue([])
    setFilters((oldFilters) => ({
      ...oldFilters,
      filterBy: filterName,
      filterSelect: event.target.value,
    }))
    integrationsData &&
      integrationsData
        .reduce((acc, curr) => {
          return acc && acc.length > 0
            ? acc.some((eachData) =>
                filterName === 'built_for'
                  ? eachData.name.toLowerCase() === curr.name.toLowerCase() &&
                    eachData.built_for === curr.built_for
                  : eachData.name.toLowerCase() === curr.name.toLowerCase()
              )
              ? [...acc]
              : [...acc, curr]
            : [acc, curr]
        })
        .forEach((data) => {
          const getFilterType =
            typeof data[filterName] === 'string'
              ? [data[filterName]]
              : data[filterName]
          getFilterType.forEach((category) =>
            category.replace(/\s/g, '') ===
            event.target.value.replace(/\s/g, '')
              ? setSearchValue((searches) => [...searches, data])
              : null
          )
        })
  }

  const onChangeSearchText = (event) => {
    setSearchValue([])

    filteredData &&
      filteredData.forEach((data) => {
        data.name.toLowerCase().indexOf(event.target.value.toLowerCase()) >
          -1 ||
        data.product_description
          .toLowerCase()
          .indexOf(event.target.value.toLowerCase()) > -1 ||
        data.company.name
          .toLowerCase()
          .indexOf(event.target.value.toLowerCase()) > -1
          ? setSearchValue((searches) => [...searches, data])
          : null
      })
  }

  return (
    <SbEditable content={props.blok}>
      <PageContainer>
        <Box
          className={classNames(classes.filterBarOuterContainer, {
            [classes.filterBarOuterContainerSticky]: isSticky,
          })}
          ref={stickyContainerRef}
        >
          {!!props.blok.filtersMenu &&
            props.blok.filtersMenu.map((filtersMenu) => (
              <ContentContainer key={filtersMenu._uid}>
                {renderBlok({
                  ...filtersMenu,
                  extractFilterOptions,
                  filters,
                  onChangeFilters,
                  onChangeSearchText,
                  isIntegrationsModule: true,
                })}
              </ContentContainer>
            ))}
        </Box>
        {filteredData && (
          <GalleryModule
            integrations={
              searchValue !== null
                ? searchValue.length > 0 && searchValue
                : filteredData
            }
            fullSlug={formatLocalizedLink(
              pageContext.full_slug || '/integrations'
            ).substring(1)}
          />
        )}
      </PageContainer>
    </SbEditable>
  )
}

export default IntegrationFilter
